<template>
  <div class="search-picker-box" @click.stop>
    <div class="search-picker-bar">
      <div class="hand" :class="{ pickerWidth: isWidth }">
        <input v-model="selector" :placeholder="title" type="text" @input="handleInput">
        <img :class="{ 'triangle-up': isShow, 'triangle-down': !isShow }" src="@/assets/image/est/picker.png" alt="">
      </div>
    </div>
    <div v-show="isShow" class="search-picker-drop-down one-col" :class="{status: title === 'Status'}">
      <div v-for="(item, index) in items" :key="index" :class="{ active: current === index}" class="hand"  @click="handleTabItem(item, index)">{{ item.label }}</div>
    </div>
    <div v-show="isShow && !items.length" class="search-picker-drop-down none-col" :class="{status: title === 'Status'}">
      <div>None</div>
    </div>
  </div>
</template>

<script>
import commonmix from '@/utils/commonmix'

export default {
  mixins: [commonmix],
  name: 'SearchPicker',
  props: {
    data: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
    isWidth: {
      type: Boolean,
      default: false
    },
    isShow: {
      type: Boolean,
      default: false
    },
    isOrigins: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    items: {
      get () {
        return this.temp || this.data
      },
      set (val) {
        this.temp = val
      }
    },
    isOriginsVisible: {
      get () {
        return this.isOrigins
      },
      set (val) {
        this.$emit('update:isOrigins', val)
      }
    }
  },
  watch: {
    isOriginsVisible (val) {
      if (val) {
        this.current = 0
        this.selector = ''
        this.isOriginsVisible = false
      }
    }
  },
  data () {
    return {
      current: 0,
      temp: null,
      selector: ''
    }
  },
  methods: {
    handleInput () {
      this.items = this.data.filter(v => {
        return v.value.toLowerCase().includes(this.selector.toLowerCase())
      })
    },
    handleTabItem (item, index) {
      if (this.$store.getters.token) {
        this.selector = item.label
        this.current = index
        this.$emit('tap-item', item)
      } else {
        this.openCnLoginModal()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.search-picker-box {
  font-size: 12px;
  position: relative;
  margin: 0 10px;
}
.search-picker-bar {
  display: flex;
}
.search-picker-bar > div {
  height: 38px;
  width: 148px;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
  border: 1px solid #5f6162;
  box-shadow: 1px 1px 1px #e1e1e1;
}

.pickerWidth {
  width: 197px!important;
}

.search-picker-bar > div {
  border-radius: 38px;
}

.search-picker-bar > div > input {
  word-break:keep-all;
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
  font-weight: bold;
  width: 100px;
  height: 30px;
  border: none;
  outline: none;
}

.search-picker-bar > div >img{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  width: 12px;
  height: 8px;
}

.search-picker-drop-down {
  background: #ffffff;
  position: absolute;
  width: 148px;
  top: 40px;
  //left: 20px;
  border-radius: 10px;
  box-shadow: 0 0 5px #e0e0e0;
  overflow-y: scroll;
}
.one-col {
  height: 180px;
  padding: 8px 0;
  z-index: 999;
}

.none-col{
  height: 180px;
  padding: 8px 0;
  z-index: 999;
}
.none-col > div {
  padding: 5px 10px;
}

.status {
  min-width: 200px;
}
.one-col > div {
  padding: 5px 10px;
}
.active {
  color: #ffffff;
  background: #014479;
}

.left {
  border-right: 1px solid #e0e0e0;
}
</style>
