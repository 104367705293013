<template>
  <div class="item-box" @click="handleCloseSelect">
    <div class="h1">准入动态查询</div>
    <div class="picker">
      <picker :key="0" :is-show="isShow1" :data="approvePlantOrigins" title="国别" @click.native="handleSelector(1)" @tap-item="handleTabItem($event, 0)"/>
      <picker :key="1" :is-show="isShow2" :data="plantNoOptions" :is-origins.sync="isOrigins" title="厂号" @click.native="handleSelector(2)" @tap-item="handleTabItem($event, 1)"/>
      <picker :key="2" :is-show="isShow3" :data="yearOptions" title="年" @click.native="handleSelector(3)" @tap-item="handleTabItem($event, 2)"/>
      <picker :key="3" :is-show="isShow4" :data="monthOptions" title="月" @click.native="handleSelector(4)" @tap-item="handleTabItem($event, 3)"/>
      <picker :key="4" :is-show="isShow5" :data="typeOptions" title="状态" @click.native="handleSelector(5)" @tap-item="handleTabItem($event, 4)"/>
      <search @click.native="handleSelector(0)" @tap-item="handleInput"/>
    </div>
    <!--表格部分-->
    <div class="header">
      <div v-for="(item, index) in items" :key="index">
        <span>{{ item }}</span>
      </div>
    </div>
    <div class="table-box">
      <div class="table-body">
        <!-- 外层节点 -->
        <div v-for="(item, index) in list" :key="index" :class="{ grey: index%2!==0}" class="item-box">
          <div class="father-item">
            <div class="item">
              <img :class="{ ehide: !item.HasChild, trans: current === index && showChildBtn }" src="@/assets/image/est/collapse.png" class="filter-btn" @click="showChild(item, index)">
              <img :src="item.src" style="width: 53px;margin: 0 10px">
              <span style="display: inline-block; width: 140px; text-align:center;">{{ item.Origin }}</span>
            </div>
            <div class="item">{{ item.PlantNo }}</div>
            <div class="item">
              <div style="display: flex; justify-content:center; align-items: center;">
                <span :class="{ color1:item.UpdateState==='_self_pause', color2:['_restore', '_estno_change', '_new_approve'].includes(item.UpdateState), color3:['_cancel', '_china_pause'].includes(item.UpdateState) }">{{ getEnStatus(item.UpdateState) }}</span>
              </div>
            </div>
            <div class="item" style="position: relative;">
              {{ item.UpdateStateDate }}
              <div v-if="item.IsNew" class="latest">New</div>
            </div>
            <div class="item fb">
              <img v-if="item.EnNewsID===0" src="@/assets/image/est/est-link-grey.png" class="link-btn">
              <img v-if="item.EnNewsID!==0" src="@/assets/image/est/est-link.png" class="link-btn" @click="linkNews(item)">
            </div>
          </div>
          <!-- 内层节点 -->
          <div v-for="(citem, cindex) in childList" v-show="current===index" :key="cindex" class="child-item">
            <div class="item">
              <img :class="{ ehide: true }" src="@/assets/image/est/collapse.png" class="filter-btn">
              <img :src="item.src" style="width: 53px;margin: 0 10px">
              <span style="display: inline-block; width: 140px; text-align:center; margin-right: 13px;">{{ item.Origin }}</span>
            </div>
            <div class="item">{{ citem.PlantNo }}</div>
            <div class="item">
              <div style="display: flex; justify-content:center; align-items: center;">
                <span :class="{ color1:citem.UpdateState==='_self_pause', color2:['_restore', '_estno_change', '_new_approve'].includes(citem.UpdateState), color3:['_cancel', '_china_pause'].includes(citem.UpdateState) }">{{ getEnStatus(citem.UpdateState) }}</span>
              </div>
            </div>
            <div class="item" style="position: relative;">
              {{ citem.UpdateStateDate }}
            </div>
            <div class="item fb">
              <img v-if="citem.EnNewsID===0" src="@/assets/image/est/est-link-grey.png" class="link-btn">
              <img v-if="citem.EnNewsID!==0" src="@/assets/image/est/est-link.png" class="link-btn" @click="linkNews(citem)">
            </div>
          </div>
        </div>
        <div v-if="list.length === 0" style="width: 100%; height: 500px; display: flex; flex-direction: column; justify-content: center; align-items: center; font-size: 18px;">
          <img style="width: 198px;height: 211px" src="@/assets/image/cn/nodata.png">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Picker from '@/views/cn/btcest/components/picker'
import Search from '@/views/cn/btcest/components/search'
import { getLocalStore } from '@/utils/common'
import commonmix from '@/utils/commonmix'

export default {
  mixins: [commonmix],
  name: 'CnBTCEstUpdates',
  components: {
    Picker,
    Search
  },
  data () {
    return {
      items: ['国别', '厂号', '状态更新', '更新时间', '必孚链接'],
      list: [],
      max: 50,
      xData: [],
      yData: [],
      tipMsg: '',
      legendVal: [],
      countryVal: '_all',
      plantNoVal: '_all',
      stateVal: '_all',
      indexQuery: {
        PageNum: 1,
        PageSize: 20
      },
      childList: [],
      approvePlantOrigins: [],
      plantNoOptions: [],
      typeOptions: [
        { value: '_all', label: '全部' },
        { value: '_restore', label: '恢复输华' },
        { value: '_china_pause', label: '中方暂停' },
        { value: '_self_pause', label: '自主暂停' },
        { value: '_new_approve', label: '新增准入' },
        { value: '_cancel', label: '取消输华' },
        { value: '_estno_change', label: '厂号变更' }
      ],
      rotate: false,
      current: -1,
      showChildBtn: false,
      currentSection: '',
      searchContent: '',
      isFirstView: true,
      syearVal: '',
      smonthVal: '',
      yearOptions: [
        { value: '', label: '全部' },
        { value: '2018', label: '2018' },
        { value: '2019', label: '2019' },
        { value: '2020', label: '2020' },
        { value: '2021', label: '2021' }
      ],
      monthOptions: [
        { value: '', label: '全部' },
        { value: '01', label: '01' },
        { value: '02', label: '02' },
        { value: '03', label: '03' },
        { value: '04', label: '04' },
        { value: '05', label: '05' },
        { value: '06', label: '06' },
        { value: '07', label: '07' },
        { value: '08', label: '08' },
        { value: '09', label: '09' },
        { value: '10', label: '10' },
        { value: '11', label: '11' },
        { value: '12', label: '12' }
      ],
      viewNumber: 0,
      isShow1: false,
      isShow2: false,
      isShow3: false,
      isShow4: false,
      isShow5: false,
      closeSelect: false,
      isOrigins: false
    }
  },
  computed: {
    articlePath () {
      // return this.isEN ? '/english/article/' : '/article/'
      return '/english/article/'
    }
  },
  watch: {
    closeSelect (val) {
      if (val) {
        this.isShow1 = false
        this.isShow2 = false
        this.isShow3 = false
        this.isShow4 = false
        this.isShow5 = false
        this.closeSelect = false
      }
    }
  },
  mounted () {
    this.getApprovePlantOrigins()
    this.getApprovePlantNos()
    this.getList()
    const _self = this
    if (document.getElementsByClassName('table-body')[0]) {
      document.getElementsByClassName('table-body')[0].addEventListener('scroll', function () {
        if (this.scrollHeight - this.scrollTop === this.clientHeight) {
          _self.getList(true)
        }
      })
    }
  },
  methods: {
    handleTabItem (item, type) {
      switch (type) {
        case 0 :
          this.countryVal = item.value
          this.plantNoVal = '_all'
          this.stateVal = '_all'
          this.getApprovePlantNos()
          this.syearVal = ''
          this.smonthVal = ''
          this.searchContent = this.countryVal
          this.isOrigins = true

          this.currentSection = '_origin'
          this.addSearchInfo()
          break
        case 1:
          this.plantNoVal = item.value
          this.searchContent = this.plantNoVal

          this.currentSection = '_estno'
          this.addSearchInfo()
          break
        case 2:
          this.syearVal = item.value
          break
        case 3:
          this.smonthVal = item.value
          break
        case 4:
          this.stateVal = item.value
          this.searchContent = this.stateVal

          this.currentSection = '_status'
          this.addSearchInfo()
          break
        default:
      }

      this.handleSearch()
    },

    handleInput (item) {
      if (this.$store.getters.token) {
        this.indexQuery.KeyWord = item
        this.handleSearch()
      } else {
        this.openCnLoginModal()
      }
    },
    addSearchInfo () {
      // 记录工厂查询信息
      this.$store.dispatch('AddWebPlantSearchInfo', {
        UserID: getLocalStore().UserID,
        SearchCategory: 'Update Status of Establishments',
        SearchSection: this.currentSection,
        SearchContent: this.searchContent
      })
    },
    getApprovePlantOrigins () {
      this.$store.dispatch('GetWebApprovePlantStateOrigin', {
        isEn: false,
        Type: 1
      }).then(() => {
        const plantOrigins = this.$store.getters.etcRow.items.map((v) => {
          return { value: v, label: v }
        })
        plantOrigins.unshift({ label: '全部', value: '_all' })
        this.approvePlantOrigins = plantOrigins
      })
    },
    getApprovePlantNos () {
      this.$store.dispatch('GetWebApprovePlantStatePlantNO', {
        Origin: this.countryVal,
        Type: 1
      }).then(() => {
        const plantOrigins = this.$store.getters.etcRow.items.map((v) => {
          return { value: v, label: v }
        })
        plantOrigins.unshift({ label: '全部', value: '_all' })
        this.plantNoOptions = plantOrigins
      })
    },
    getList (isMore) {
      if (isMore) {
        this.indexQuery.PageNum += 1
      } else {
        this.indexQuery.PageNum = 1
      }
      this.indexQuery.Origin = this.countryVal
      this.indexQuery.PlantNo = this.plantNoVal
      this.indexQuery.UpdateState = this.stateVal
      this.indexQuery.LanguageCode = 'zh-CN'
      this.indexQuery.UserID = getLocalStore().UserID
      this.indexQuery.IsFirst = this.isFirstView
      this.indexQuery.Year = this.syearVal
      this.indexQuery.Month = this.smonthVal.replace('0', '')
      this.indexQuery.Type = 1
      this.$store.dispatch('SetLoadingStatus', true)
      this.$store.dispatch('GetWebApprovePlantState', this.indexQuery).then(() => {
        if (isMore) {
          this.list = this.list.concat(this.$store.getters.etcRows)
        } else {
          this.list = this.$store.getters.etcRows
        }
        this.getIcon()
        this.$store.dispatch('SetLoadingStatus', false)
      }).catch((err) => {
        if (err.ErrorCode === 6004) {
          this.$router.push('/english/charge')
        }
        this.$store.dispatch('SetLoadingStatus', false)
      })
    },
    getChildList (item) {
      this.$store.dispatch('SetLoadingStatus', true)
      this.$store.dispatch('GetWebApprovePlantChildState', {
        Type: 1,
        PlantNo: item.PlantNo,
        ApproveStateID: item.ApproveStateID
      }).then(() => {
        this.childList = this.$store.getters.etcRows
        this.$store.dispatch('SetLoadingStatus', false)
      }).catch((err) => {
        console.log(err)
        this.$store.dispatch('SetLoadingStatus', false)
      })
    },
    handleChange (item) {
      if (item === '_origin') {
        this.plantNoVal = '_all'
        this.stateVal = '_all'
        this.getApprovePlantNos()
        this.syearVal = ''
        this.smonthVal = ''
        this.searchContent = this.countryVal
      }
      if (item === '_estno') {
        this.searchContent = this.plantNoVal
      }
      if (item === '_status') {
        this.searchContent = this.stateVal
      }
      this.currentSection = item
      this.addSearchInfo()
    },
    linkNews (item) {
      if (this.$store.getters.token) {
        this.$store.commit('SET_NEWSID', item.NewsID)
        // 跳转新界面
        // this.$router.push(`/BTCInfo/Article/${this.other.NewsID || this.data.NewsID}`)
        const path = `/cn/BTCInfo/ArticleFree/${item.NewsID}`
        const routeData = this.$router.resolve({ path: path, query: { rid: item.NewsID } })
        const open = window.open(routeData.href, '_blank')
        if (open === null || typeof (open) === 'undefined') {
          this.$router.push(path)
        }
      } else {
        this.openCnLoginModal()
      }

      // if (this.$store.getters.token) {
      //   const path = `${this.articlePath}${item.EnNewsID}`
      //   const routeData = this.$router.resolve({ path: path })
      //   const open = window.open(routeData.href, '_blank')
      //   if (open === null || typeof (open) === 'undefined') {
      //     this.$router.push(path)
      //   }
      // } else {
      //   this.openLoginModal()
      // }
    },
    showChild (item, index) {
      if (this.current !== index) {
        this.current = index
        this.getChildList(item)
        this.showChildBtn = true
      } else {
        this.current = -1
        this.showChildBtn = false
      }
      this.rotate = !this.rotate
    },
    handleSearch () {
      this.trial(false)
    },
    trial (isRead) {
      // if (this.$store.getters.token) {
      this.getList()
      // } else {
      // this.openLoginModal()
      // }
    },
    getEnStatus (item) {
      const typeFilter = this.typeOptions.filter(v => {
        return v.value === item
      })
      return typeFilter[0].label
    },
    getIcon () {
      this.list.forEach(item => {
        if (item && item.Origin) {
          const arr = []
          const temp = item.OriginEn.split(' ')
          for (let i = 0; i < temp.length; i++) {
            if (temp[i] === '') continue
            arr.push(temp[i])
          }
          item.src = require(`@/assets/image/est/country/Pork${arr.join('')}.png`)
        }
        return null
      })
    },
    handleCloseSelect () {
      this.closeSelect = true
    },
    handleSelector (type) {
      switch (type) {
        case 1:
          this.isShow1 = !this.isShow1
          this.isShow2 = false
          this.isShow3 = false
          this.isShow4 = false
          this.isShow5 = false
          break
        case 2:
          this.isShow1 = false
          this.isShow2 = !this.isShow2
          this.isShow3 = false
          this.isShow4 = false
          this.isShow5 = false
          break
        case 3:
          this.isShow1 = false
          this.isShow2 = false
          this.isShow3 = !this.isShow3
          this.isShow4 = false
          this.isShow5 = false
          break
        case 4:
          this.isShow1 = false
          this.isShow2 = false
          this.isShow3 = false
          this.isShow4 = !this.isShow4
          this.isShow5 = false
          break
        case 5:
          this.isShow1 = false
          this.isShow2 = false
          this.isShow3 = false
          this.isShow4 = false
          this.isShow5 = !this.isShow5
          break
        default:
          this.isShow1 = false
          this.isShow2 = false
          this.isShow3 = false
          this.isShow4 = false
          this.isShow5 = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>

$cell-width: 240px;
$cell-height: 52px;

.item-box{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.h1{
  font-size: 30px;
  font-weight: bold;
  padding: 40px 0 10px 0;
}

.picker{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
}

// 表头
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  height: $cell-height;
  border-radius: 7px;
  background: #e6e6e6;
  font-weight: bold;
  font-size: 16px;
  color: #333333;
}

.header > div {
  min-width: $cell-width;
  text-align: center;
}

::-webkit-scrollbar {
  display: none;
}

.table-body {
  margin-top: 10px;
  height: 1000px;
  min-width: 1200px;
  overflow-y: scroll;
}
.table-body > .item-box {
  min-height: $cell-height;
}
.table-body > .item-box > .father-item {
  //height: $cell-height;
  //display: flex;
  height: $cell-height;
  border-radius: 7px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 10px;
  box-shadow: 0 1px 10px 1px #e1e1e1;
}
.table-body > .item-box > .father-item > .item {
  width: $cell-width;
  height: $cell-height;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 14px;
}
.table-body > .item-box > .child-item {
  background: rgba(242,242,242,1)!important;
  height: $cell-height;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 10px;
  box-shadow: 0 1px 10px 1px #e1e1e1;
}

.table-body > .item-box > .child-item > .item {
  width: $cell-width;
  height: $cell-height;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 14px;
  animation: slide-down .5s ease-in;
  -moz-animation: slide-down .5s ease-in;
  -webkit-animation: slide-down .5s ease-in;
  transition: max-height .3s ease-in;
}
.ehide {
  visibility: hidden;
}
.color1 {
  color: #d46767!important;
}
.color2 {
  color: #4cac78!important;
}
.color3 {
  color: #ed6666!important;
}
.show-child-btn {
  width: 14px;
  height: 14px;
  cursor: pointer;
  margin-left: 5px;
  background: #a5a5a5;
  text-align: center;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
}
.filter-btn {
  margin: 0 10px;
  width: 14px;
  height: 9px;
  cursor: pointer;
}
.link-btn {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.trans {
  transform: rotate(180deg)
}

@-webkit-keyframes slide-down{
  0%{transform: scale(1,0);}
  25%{transform: scale(1,1.2);}
  50%{transform: scale(1,0.85);}
  75%{transform: scale(1,1.05);}
  100%{transform: scale(1,1);}
}

</style>
