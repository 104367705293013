<template>
  <div class="search-picker-box">
    <div :class="[type === 'mini' ? 'search-picker-bar-min': 'search-picker-bar']">
      <input v-model="input" type="text" @keyup.enter="handleInput">
      <img src="@/assets/image/data/search.png" class="hand" @click="handleInput" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: 'Search',
  props: {
    type: {
      type: String,
      default: ''
    }
  },
  computed: {
  },
  data () {
    return {
      input: ''
    }
  },
  methods: {
    handleInput () {
      this.$emit('tap-item', this.input)
    }
  }
}
</script>

<style lang="scss" scoped>
.search-picker-box {
  font-size: 12px;
  position: relative;
  margin-right: 20px;
}

.search-picker-bar {
  height: 38px;
  width: 148px;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
  border: 1px solid #5f6162;
  box-shadow: 1px 1px 1px #e1e1e1;
  border-radius: 38px;
}

.search-picker-bar-min {
  height: 28px;
  width: 128px;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
  border: 1px solid #5f6162;
  box-shadow: 1px 1px 1px #e1e1e1;
  border-radius: 28px;
}

.search-picker-bar > input {
  width: 110px;
  height: 30px;
  border: none;
  outline: none;
}

.search-picker-bar > img {
  width: 12px;
  height: 12px;
}

.search-picker-bar-min > input {
  width: 100px;
  height: 20px;
  border: none;
  outline: none;
}

.search-picker-bar-min > img {
  width: 10px;
  height: 10px;
}
</style>
